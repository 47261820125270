import { useStaticQuery, graphql } from 'gatsby'

export function useDataThirdScreenByHy() {
    const data = useStaticQuery(graphql`
        {
            sectionTitle: datoCmsRenshinPageMain(title: { eq: "Slide 3: Title small" }, locale: { eq: "hy" }) {
                value
            }

            title: datoCmsRenshinPageMain(title: { eq: "Slide 3: Title" }, locale: { eq: "hy" }) {
                value
            }

            text: datoCmsRenshinPageMain(title: { eq: "Slide 3: Text" }, locale: { eq: "hy" }) {
                value
            }

            buttonText: datoCmsRenshinPageMain(title: { eq: "Slide 3: Button" }, locale: { eq: "hy" }) {
                value
            }
        }
    `)

    return data
}
