import React, { useMemo } from 'react'
import * as classes from './SixthScreen.module.scss'
import cn from 'classnames'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import IconRight from '../../../icons/IconRight'
import { useDataSixthScreenByHy } from './useDataSixthScreenByHy'
import { deleteHtmlTag } from '../../../../utils/deleteHtmlTag'
import { useDataSixthScreenByEn } from './useDataSixthScreenByEn'
import { Link } from 'gatsby'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'

function SixthScreen({ lang, nextSlide, prevSlide }) {
    const dataByHy = useDataSixthScreenByHy()
    const dataByEn = useDataSixthScreenByEn()

    const data = lang === 'hy' ? dataByHy : dataByEn
    const sectionTitle = useMemo(() => deleteHtmlTag(data.sectionTitle.value, 'p'), [])
    const title = useMemo(() => deleteHtmlTag(data.title.value, 'p'), [])

    const slideNext = (direction = 'next') => {
        if (document.querySelector('[data-main-img-container="Y"] [data-active="Y"]')) {
            const slideCount = data.projects.nodes.length

            let idCurrent = document.querySelector('[data-main-img-container="Y"] [data-active="Y"]').getAttribute('data-id')
            let idNext = false

            if (idCurrent) {
                idCurrent = parseInt(idCurrent)

                if (direction === 'prev') {
                    if (idCurrent === 1) {
                        idNext = slideCount
                    } else {
                        idNext = idCurrent - 1
                    }
                } else {
                    if (idCurrent === slideCount) {
                        idNext = 1
                    } else {
                        idNext = idCurrent + 1
                    }
                }

                if (idCurrent && idNext) {
                    document.querySelector(`[data-main-img-container="Y"] [data-id="${idCurrent}"]`).style.zIndex = '3'
                    document.querySelector(`[data-main-img-container="Y"] [data-id="${idCurrent}"]`).removeAttribute('data-active')
                    document.querySelector(`[data-main-img-container="Y"] [data-id="${idCurrent}"]`).setAttribute('data-animation', 'Y')

                    document.querySelector(`[data-main-img-container="Y"] [data-id="${idNext}"]`).style.zIndex = '2'
                    document.querySelector(`[data-main-img-container="Y"] [data-id="${idNext}"]`).setAttribute('data-animation-next', 'Y')

                    document.querySelector(`[data-main-img-container-mobile="Y"] [data-id="${idCurrent}"]`).style.zIndex = '3'
                    document.querySelector(`[data-main-img-container-mobile="Y"] [data-id="${idCurrent}"]`).removeAttribute('data-active')
                    document
                        .querySelector(`[data-main-img-container-mobile="Y"] [data-id="${idCurrent}"]`)
                        .setAttribute('data-animation', 'Y')

                    document.querySelector(`[data-main-img-container-mobile="Y"] [data-id="${idNext}"]`).style.zIndex = '2'
                    document
                        .querySelector(`[data-main-img-container-mobile="Y"] [data-id="${idNext}"]`)
                        .setAttribute('data-animation-next', 'Y')

                    document.querySelector(`.${classes.Dots} [data-id="${idCurrent}"]`).removeAttribute('data-active')
                    document.querySelector(`.${classes.Dots} [data-id="${idNext}"]`).setAttribute('data-active', 'Y')

                    setTimeout(() => {
                        document.querySelector(`[data-main-img-container="Y"] [data-id="${idCurrent}"]`).removeAttribute('style')
                        document.querySelector(`[data-main-img-container="Y"] [data-id="${idCurrent}"]`).removeAttribute('data-animation')

                        document.querySelector(`[data-main-img-container="Y"] [data-id="${idNext}"]`).removeAttribute('data-animation-next')
                        document.querySelector(`[data-main-img-container="Y"] [data-id="${idNext}"]`).removeAttribute('style')
                        document.querySelector(`[data-main-img-container="Y"] [data-id="${idNext}"]`).setAttribute('data-active', 'Y')

                        document.querySelector(`[data-main-img-container-mobile="Y"] [data-id="${idCurrent}"]`).removeAttribute('style')
                        document
                            .querySelector(`[data-main-img-container-mobile="Y"] [data-id="${idCurrent}"]`)
                            .removeAttribute('data-animation')

                        document
                            .querySelector(`[data-main-img-container-mobile="Y"] [data-id="${idNext}"]`)
                            .removeAttribute('data-animation-next')
                        document.querySelector(`[data-main-img-container-mobile="Y"] [data-id="${idNext}"]`).removeAttribute('style')
                        document
                            .querySelector(`[data-main-img-container-mobile="Y"] [data-id="${idNext}"]`)
                            .setAttribute('data-active', 'Y')
                    }, 1530)
                }
            }
        }
    }

    return (
        <div className={classes.Inner}>
            <ReactScrollWheelHandler
                upHandler={prevSlide}
                downHandler={nextSlide}
                leftHandler={slideNext}
                rightHandler={() => slideNext('prev')}
                wheelConfig={[5, 20, 0.05]}
            >
                <div className={`${classes.AllContent} main-slider-content`}>
                    <SectionTitle className={classes.SectionTitle} title={sectionTitle} lang={lang} light />

                    <h2 className={cn(classes.Title, { [classes.LangEN]: lang === 'en' })} dangerouslySetInnerHTML={{ __html: title }} />
                </div>

                <p className={`${classes.SlideTitle} ${classes.SlideTitle__768} main-slider-inner-name`} data-main-img-container-mobile='Y'>
                    {data.projects.nodes.map((item, index) => (
                        <Link
                            key={index}
                            to={`${lang === 'en' ? '/en' : ''}/projects/${item.urlCode}/`}
                            data-id={index + 1}
                            data-active={index === 0 ? 'Y' : null}
                        >
                            <span className={classes.TitleMobile}>
                                <span>{item.title}</span>
                            </span>
                        </Link>
                    ))}
                </p>

                <div className={classes.Slides} data-main-img-container='Y'>
                    {data.projects.nodes.map((item, index) => (
                        <Link
                            key={index}
                            to={`${lang === 'en' ? '/en' : ''}/projects/${item.urlCode}/`}
                            className={classes.ImageWrap}
                            data-id={index + 1}
                            data-active={index === 0 ? 'Y' : null}
                        >
                            <div className={classes.Image} style={{ backgroundImage: `url(${item.mainBackground?.url})` }} />

                            <p className={`${classes.SlideTitle} main-slider-inner-name`}>
                                <span>{item.title}</span>
                            </p>
                        </Link>
                    ))}

                    {data.projects.nodes && data.projects.nodes.length > 1 ? (
                        <button className={`${classes.BtnNext} main-slider-btnNext`} onClick={slideNext}>
                            <p className={classes.IconRightWrap}>
                                <IconRight />
                            </p>
                        </button>
                    ) : null}
                </div>

                <div className={classes.Dots}>
                    {data.projects.nodes.map((item, index) => (
                        <div key={index} className={classes.Dot} data-id={index + 1} data-active={index === 0 ? 'Y' : null} />
                    ))}
                </div>
            </ReactScrollWheelHandler>
        </div>
    )
}

export default SixthScreen
