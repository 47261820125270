import React from 'react'
import * as classes from './ScrollControl.module.scss'
import cn from 'classnames'
import Numbers from '../Numbers/Numbers'

function ScrollControl({ activeSlide, dots, numbers }) {
    return (
        <div className={cn(classes.Circle, { [classes.Dark]: activeSlide === 3 })}>
            <div className={classes.Animation}>
                <span className={classes.AnimationLine} />
            </div>

            <div className={classes.Wrap}>
                <div className={classes.ActiveInner}>
                    <div className={`${classes.ActiveDotWrap} ${classes.Active}`} id='active-DotWrap'>
                        <div className={classes.ActiveDot}>
                            <Numbers numbers={numbers} />
                        </div>
                    </div>
                </div>

                <div className={cn(classes.Inner)} id='scrollControl'>
                    {dots.map((item, index) => (
                        <div className={cn(classes.DotWrap)} key={item.id} data-id={item.id} id='scrollControlV2-DotWrap'>
                            <span className={classes.Dot} />
                        </div>
                    ))}
                </div>
            </div>

            <p className={classes.Text}>SCROLL DOWN</p>
        </div>
    )
}

export default ScrollControl
