// extracted by mini-css-extract-plugin
export var Circle = "ScrollControl-module--Circle--Va5vH";
export var Dark = "ScrollControl-module--Dark--olc16";
export var Inner = "ScrollControl-module--Inner--VORYx";
export var Animation = "ScrollControl-module--Animation--iaLgA";
export var AnimationLine = "ScrollControl-module--AnimationLine--o7QvD";
export var Text = "ScrollControl-module--Text--jV0dE";
export var DotWrap = "ScrollControl-module--DotWrap--rA+j5";
export var ActiveDotWrap = "ScrollControl-module--ActiveDotWrap--O8F8t";
export var ActiveDot = "ScrollControl-module--ActiveDot--pE7Bu";
export var Dot = "ScrollControl-module--Dot--p5jwB";
export var Wrap = "ScrollControl-module--Wrap--IjyTx";
export var ActiveInner = "ScrollControl-module--ActiveInner--CWN-W";
export var animationLine = "ScrollControl-module--animationLine--19Q4W";