import { useStaticQuery, graphql } from 'gatsby'

export function useDataSecondScreenByHy() {
    const data = useStaticQuery(graphql`
        {
            sectionTitle: datoCmsRenshinPageMain(title: { eq: "Slide 2: Title" }, locale: { eq: "hy" }) {
                value
            }

            title: datoCmsRenshinPageMain(title: { eq: "Slide 2: Text" }, locale: { eq: "hy" }) {
                value
            }
        }
    `)

    return data
}
