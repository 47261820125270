import { useStaticQuery, graphql } from 'gatsby'

export function useDataSeventhScreenByEn() {
    const data = useStaticQuery(graphql`
        {
            sectionTitle: datoCmsRenshinPageMain(title: { eq: "Slide 7: Title" }, locale: { eq: "en" }) {
                value
            }

            linkCompany: datoCmsRenshinPageMain(title: { eq: "Slide 7: Link company" }, locale: { eq: "en" }) {
                value
            }

            linkNews: datoCmsRenshinPageMain(title: { eq: "Slide 7: Link news" }, locale: { eq: "en" }) {
                value
            }

            seo: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: "en" }) {
                footerLinks {
                    title
                    url
                }
            }

            copy: datoCmsRenshinPageOther(title: { eq: "License" }, locale: { eq: "en" }) {
                value
            }

            policy: datoCmsRenshinPageOther(title: { eq: "Privacy Policy" }, locale: { eq: "en" }) {
                value
            }

            developer: datoCmsRenshinPageOther(title: { eq: "Developer" }, locale: { eq: "en" }) {
                value
            }
        }
    `)

    return data
}
