import React, { useMemo } from 'react'
import * as classes from './FifthScreen.module.scss'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { deleteHtmlTag } from './../../../../utils/deleteHtmlTag'
import { useDataFifthScreenByHy } from './useDataFifthScreenByHy'
import { useDataFifthScreenByEn } from './useDataFifthScreenByEn'

function FifthScreen({ lang }) {
    const dataByHy = useDataFifthScreenByHy()
    const dataByEn = useDataFifthScreenByEn()

    const data = lang === 'hy' ? dataByHy : dataByEn

    const sectionTitle = useMemo(() => deleteHtmlTag(data.sectionTitle.value, 'p'), [])
    const totalNum = useMemo(() => deleteHtmlTag(data.totalNum.value, 'p'), [])
    const totalText = useMemo(() => deleteHtmlTag(data.totalText.value, 'p'), [])

    const items = useMemo(
        () => [
            {
                count: deleteHtmlTag(data.itemTitle_1.value, 'p'),
                title: deleteHtmlTag(data.itemText_1.value, 'p')
            },

            {
                count: deleteHtmlTag(data.itemTitle_2.value, 'p'),
                title: deleteHtmlTag(data.itemText_2.value, 'p')
            }
        ],
        []
    )

    return (
        <div className={classes.Inner}>
            <div className={`${classes.AllContent} main-slider-content`}>
                <SectionTitle className={classes.SectionTitle} title={sectionTitle} lang={lang} light />

                <div className={classes.Row}>
                    <div className={classes.Total}>
                        <div className={classes.TotalNum}>
                            {totalNum}
                            <span className={classes.TotalPlus}>+</span>

                            <p className={classes.TotalText} dangerouslySetInnerHTML={{ __html: totalText }} />
                        </div>
                    </div>

                    <div className={classes.Details}>
                        {items.map((item, index) => (
                            <div className={classes.DetailsItem} key={index}>
                                <span className={classes.DetailsItemNum}>{item.count}</span>
                                <p className={classes.DetailsItemText} dangerouslySetInnerHTML={{ __html: item.title }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FifthScreen
