import React from 'react'
import * as classes from './ScrollControlTablet.module.scss'
import cn from 'classnames'
import Numbers from '../Numbers/Numbers'

function ScrollControlTablet({ activeSlide, dots, numbers }) {
    return (
        <div className={cn(classes.Circle, { [classes.Dark]: activeSlide === 3 })}>
            <div className={classes.Animation}>
                <span className={classes.AnimationLine} />
            </div>

            <div className={classes.Control}>
                <div className={`${classes.ActiveDot} ${classes.Active}`} id='active-dot-tablet'>
                    <Numbers numbers={numbers} activeSlide={activeSlide} />
                </div>

                {dots.map((item) => (
                    <div className={cn(classes.Dot)} key={item.id} data-id={item.id} />
                ))}
            </div>

            <p className={classes.Text}>SWIPE DOWN</p>
        </div>
    )
}

export default ScrollControlTablet
