import React, { useMemo } from 'react'
import * as classes from './SecondScreen.module.scss'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { useDataSecondScreenByHy } from './useDataSecondScreenByHy'
import { deleteHtmlTag } from './../../../../utils/deleteHtmlTag'
import { useDataSecondScreenByEn } from './useDataSecondScreenByEn'

function SecondScreen({ lang }) {
    const dataByHy = useDataSecondScreenByHy()
    const dataByEn = useDataSecondScreenByEn()

    const data = lang === 'hy' ? dataByHy : dataByEn

    const sectionTitle = useMemo(() => deleteHtmlTag(data.sectionTitle.value, 'p'), [])
    const title = useMemo(() => deleteHtmlTag(data.title.value, 'p'), [])

    return (
        <div className={classes.Inner}>
            <div className={`${classes.AllContent} main-slider-content`}>
                <SectionTitle className={classes.SectionTitle} title={sectionTitle} lang={lang} light />

                <h2 className={classes.Title} dangerouslySetInnerHTML={{ __html: title }} />
            </div>
        </div>
    )
}

export default SecondScreen
