import React, { useMemo } from 'react'
import * as classes from './SeventhScreen.module.scss'
import cn from 'classnames'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { Link } from 'gatsby'
import IconRight from '../../../icons/IconRight'
import { useDataSeventhScreenByHy } from './useDataSeventhScreenByHy'
import { deleteHtmlTag } from './../../../../utils/deleteHtmlTag'
import { useDataSeventhScreenByEn } from './useDataSeventhScreenByEn'
import IconLogoBg from './IconLogoBg'

function SeventhScreen({ lang }) {
    const dataByHy = useDataSeventhScreenByHy()
    const dataByEn = useDataSeventhScreenByEn()
    const data = lang === 'hy' ? dataByHy : dataByEn

    const sectionTitle = useMemo(() => deleteHtmlTag(data.sectionTitle.value, 'p'), [])
    const policyLink = useMemo(() => deleteHtmlTag(data.policy.value, 'p'), [])
    const copy = useMemo(() => deleteHtmlTag(data.copy.value, 'p'), [])
    const developer = useMemo(() => deleteHtmlTag(data.developer.value, 'p'), [])

    return (
        <div className={classes.Inner}>
            <div className={`${classes.AllContent} main-slider-content`}>
                <SectionTitle className={classes.SectionTitle} title={sectionTitle} lang={lang} light />

                <div className={classes.PageNavigationWrap}>
                    <ul className={classes.Links}>
                        {data.seo.footerLinks.map((item, index) => (
                            <li className={classes.Link} key={index}>
                                <Link to={item.url}>
                                    {item.title}

                                    <p className={classes.IconRightWrap}>
                                        <IconRight />
                                    </p>

                                    <span className={`${classes.Line}`} />
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={classes.Footer}>
                    <p className={classes.Copyright} dangerouslySetInnerHTML={{ __html: copy }} />

                    <p
                        className={cn(classes.Policy, { [classes.Hidden]: lang === 'hy' })}
                        dangerouslySetInnerHTML={{ __html: policyLink }}
                    />

                    <p className={classes.Author} dangerouslySetInnerHTML={{ __html: developer }} />
                </div>
            </div>

            <IconLogoBg className={classes.Decor} />
        </div>
    )
}

export default SeventhScreen
