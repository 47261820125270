import anime from 'animejs'

export function onPrevSlideTablet(activeSlide, setActiveSlide, isMobail) {
    const nextSlideIndex = activeSlide === 0 ? 7 : activeSlide - 1

    const currentSlideElem = document.getElementById(`intro-slide-${activeSlide}`)
    const nextSlideElem = document.getElementById(`intro-slide-${nextSlideIndex}`)

    const contentByCurrentElem = currentSlideElem.querySelector('.main-slider-content')
    const contentByNextElem = nextSlideElem.querySelector('.main-slider-content')

    const nameSlideBySixthScreenByCurrent = currentSlideElem.querySelectorAll('.main-slider-inner-name')
    const btnNextBySixthScreenByByCurrent = currentSlideElem.querySelector('.main-slider-btnNext')
    const nameSlideBySixthScreenByNext = nextSlideElem.querySelectorAll('.main-slider-inner-name')
    const btnNextBySixthScreenByNext = nextSlideElem.querySelector('.main-slider-btnNext')

    const activeDot = document.getElementById(`active-dot-tablet`)
    const scrollControlNumbers = document.getElementById(`scrollControl-numbers`)

    const prev = scrollControlNumbers.querySelector(`[data-id="${activeSlide}"]`)
    const next = scrollControlNumbers.querySelector(`[data-id="${nextSlideIndex}"]`)

    const offsetDot = isMobail ? 17 : 25
    const stepOffsetDot = isMobail ? 46 : 76
    const minWidthDot = isMobail ? 4 : 6
    const maxWidthDot = isMobail ? 34 : 50

    prev.style.top = '50%'
    prev.style.transform = `none`

    anime
        .timeline()
        .add({
            targets: activeDot, // scale dot
            duration: 300,
            easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
            width: [`${maxWidthDot}`, `${minWidthDot}`],
            height: [`${maxWidthDot}`, `${minWidthDot}`],
            top: [`${(activeSlide - 1) * stepOffsetDot - offsetDot}`, `${(activeSlide - 1) * stepOffsetDot}`]
        })
        .add({
            targets: activeDot, // transition dot
            duration: 1200,
            easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
            top: [`${(activeSlide - 1) * stepOffsetDot}`, `${(activeSlide - 1) * stepOffsetDot - stepOffsetDot}`]
        })

        .add({
            targets: activeDot, // scale dot
            duration: 300,
            easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
            width: [`${minWidthDot}`, `${maxWidthDot}`],
            height: [`${minWidthDot}`, `${maxWidthDot}`],
            top: [
                `${(activeSlide - 1) * stepOffsetDot - stepOffsetDot}`,
                `${(activeSlide - 1) * stepOffsetDot - (stepOffsetDot + offsetDot)}`
            ]
        })

    anime({
        targets: scrollControlNumbers, // hidden number slide
        duration: 300,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
        opacity: [1, 0]
    })

    anime({
        targets: scrollControlNumbers, // visible number slide
        duration: 160,
        delay: 1600,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
        opacity: [0, 1]
    })

    anime({
        targets: currentSlideElem,
        duration: 1500,
        easing: 'cubicBezier(0.8, 0, 0.2, 1)',
        top: [0, '100%']
    })

    anime({
        targets: nextSlideElem,
        duration: 1500,
        easing: 'cubicBezier(0.8, 0, 0.2, 1)',
        top: ['-100%', '0%'],
        scale: [0.8, 1]
    })

    // контент внутри слайда current
    anime({
        targets: contentByCurrentElem,
        duration: 750,
        easing: 'cubicBezier(0.42, 0, 1, 1)',
        opacity: [1, 0]
    })

    // контент внутри слайда next
    anime({
        targets: contentByNextElem,
        delay: 750,
        duration: 750,
        easing: 'cubicBezier(0.42, 0, 1, 1)',
        opacity: [0, 1]
    })

    if (nameSlideBySixthScreenByNext) {
        anime({
            targets: nameSlideBySixthScreenByNext,
            delay: 750,
            duration: 750,
            easing: 'cubicBezier(0.42, 0, 1, 1)',
            opacity: [0, 1]
        })
    }

    if (btnNextBySixthScreenByNext) {
        anime({
            targets: btnNextBySixthScreenByNext,
            delay: 750,
            duration: 750,
            easing: 'cubicBezier(0.42, 0, 1, 1)',
            opacity: [0, 1]
        })
    }

    if (nameSlideBySixthScreenByCurrent) {
        anime({
            targets: nameSlideBySixthScreenByCurrent,
            duration: 750,
            easing: 'cubicBezier(0.42, 0, 1, 1)',
            opacity: [1, 0]
        })
    }

    if (btnNextBySixthScreenByByCurrent) {
        anime({
            targets: btnNextBySixthScreenByByCurrent,
            duration: 750,
            easing: 'cubicBezier(0.42, 0, 1, 1)',
            opacity: [1, 0]
        })
    }

    anime({
        targets: prev, // current number slide
        duration: 1000,
        delay: 1800,
        top: [`${50}%`, `${100}%`],
        translateX: ['-50%', '-50%'],
        translateY: ['-50%', '0%'],
        opacity: [1, 0]
    })

    anime({
        targets: next, // next number slide
        duration: 1000,
        delay: 1800,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
        top: [`${-100}%`, `${50}%`],
        opacity: [0, 1]
    })

    setActiveSlide(nextSlideIndex)
    return
}
