import { useStaticQuery, graphql } from 'gatsby'

export function useDataFourthScreenByEn() {
    const data = useStaticQuery(graphql`
        {
            sectionTitle: datoCmsRenshinPageMain(title: { eq: "Slide 2: Title" }, locale: { eq: "en" }) {
                value
            }

            totalNum: datoCmsRenshinPageMain(title: { eq: "Slide 4: Block 1, title" }, locale: { eq: "en" }) {
                value
            }

            totalText: datoCmsRenshinPageMain(title: { eq: "Slide 4: Block 1, value" }, locale: { eq: "en" }) {
                value
            }

            itemTitle_1: datoCmsRenshinPageMain(title: { eq: "Slide 4: Block 2, title" }, locale: { eq: "en" }) {
                value
            }

            itemText_1: datoCmsRenshinPageMain(title: { eq: "Slide 4: Block 2, value" }, locale: { eq: "en" }) {
                value
            }

            itemTitle_2: datoCmsRenshinPageMain(title: { eq: "Slide 4: Block 3, title" }, locale: { eq: "en" }) {
                value
            }

            itemText_2: datoCmsRenshinPageMain(title: { eq: "Slide 4: Block 3, value" }, locale: { eq: "en" }) {
                value
            }

            itemTitle_3: datoCmsRenshinPageMain(title: { eq: "Slide 4: Block 4, title" }, locale: { eq: "en" }) {
                value
            }

            itemText_3: datoCmsRenshinPageMain(title: { eq: "Slide 4: Block 4, value" }, locale: { eq: "en" }) {
                value
            }

            itemTitle_4: datoCmsRenshinPageMain(title: { eq: "Slide 4: Block 5, title" }, locale: { eq: "en" }) {
                value
            }

            itemText_4: datoCmsRenshinPageMain(title: { eq: "Slide 4: Block 5, value" }, locale: { eq: "en" }) {
                value
            }
        }
    `)

    return data
}
