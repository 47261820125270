import React from 'react'
import * as classes from './Numbers.module.scss'
import cn from 'classnames'

function Numbers({ activeSlide }) {
    return (
        <p className={classes.Numbers} id='scrollControl-numbers'>
            <span className={cn(classes.Number, { [classes.Current]: activeSlide === 1 })} data-id={1}>
                <svg width={8} height={36} viewBox='0 0 8 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M5.4541 32.167C5.4541 33.111 5.58431 33.8516 5.84473 34.3887C6.12142 34.9258 6.55273 35.3001 7.13867 35.5117H0.400391C1.40951 35.1374 1.91406 34.0225 1.91406 32.167V3.7002C1.91406 2.6748 1.77572 1.90169 1.49902 1.38086C1.23861 0.84375 0.791016 0.469401 0.15625 0.257812H7.28516C6.06445 0.713542 5.4541 1.861 5.4541 3.7002V32.167Z'
                        fill='white'
                    />
                </svg>
            </span>

            <span className={cn(classes.Number, { [classes.Current]: activeSlide === 2 })} data-id={2}>
                <svg width={21} height={36} viewBox='0 0 21 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M5.29785 31.9092C5.29785 32.8532 5.42806 33.5938 5.68848 34.1309C5.96517 34.668 6.39648 35.0423 6.98242 35.2539H0.244141C1.25326 34.8796 1.75781 33.7646 1.75781 31.9092V3.44238C1.75781 2.41699 1.61947 1.64388 1.34277 1.12305C1.08236 0.585938 0.634766 0.211589 0 0H7.12891C5.9082 0.455729 5.29785 1.60319 5.29785 3.44238V31.9092Z'
                        fill='white'
                    />
                    <path
                        d='M18.4072 31.9092C18.4072 32.8532 18.5374 33.5938 18.7979 34.1309C19.0745 34.668 19.5059 35.0423 20.0918 35.2539H13.3535C14.3626 34.8796 14.8672 33.7646 14.8672 31.9092V3.44238C14.8672 2.41699 14.7288 1.64388 14.4521 1.12305C14.1917 0.585938 13.7441 0.211589 13.1094 0H20.2383C19.0176 0.455729 18.4072 1.60319 18.4072 3.44238V31.9092Z'
                        fill='white'
                    />
                </svg>
            </span>

            <span className={cn(classes.Number, { [classes.Current]: activeSlide === 3 })} data-id={3}>
                <svg width={34} height={36} viewBox='0 0 34 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M5.79004 32.167C5.79004 33.111 5.92025 33.8516 6.18066 34.3887C6.45736 34.9258 6.88867 35.3001 7.47461 35.5117H0.736328C1.74544 35.1374 2.25 34.0225 2.25 32.167V3.7002C2.25 2.6748 2.11165 1.90169 1.83496 1.38086C1.57454 0.84375 1.12695 0.469401 0.492188 0.257812H7.62109C6.40039 0.713542 5.79004 1.861 5.79004 3.7002V32.167Z'
                        fill='white'
                    />
                    <path
                        d='M18.8994 32.167C18.8994 33.111 19.0296 33.8516 19.29 34.3887C19.5667 34.9258 19.998 35.3001 20.584 35.5117H13.8457C14.8548 35.1374 15.3594 34.0225 15.3594 32.167V3.7002C15.3594 2.6748 15.221 1.90169 14.9443 1.38086C14.6839 0.84375 14.2363 0.469401 13.6016 0.257812H20.7305C19.5098 0.713542 18.8994 1.861 18.8994 3.7002V32.167Z'
                        fill='white'
                    />
                    <path
                        d='M32.0088 32.167C32.0088 33.111 32.139 33.8516 32.3994 34.3887C32.6761 34.9258 33.1074 35.3001 33.6934 35.5117H26.9551C27.9642 35.1374 28.4688 34.0225 28.4688 32.167V3.7002C28.4688 2.6748 28.3304 1.90169 28.0537 1.38086C27.7933 0.84375 27.3457 0.469401 26.7109 0.257812H33.8398C32.6191 0.713542 32.0088 1.861 32.0088 3.7002V32.167Z'
                        fill='white'
                    />
                </svg>
            </span>

            <span className={cn(classes.Number, { [classes.Current]: activeSlide === 4 })} data-id={4}>
                <svg width={44} height={37} viewBox='0 0 44 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M5.83301 32.167C5.83301 33.111 5.96322 33.8516 6.22363 34.3887C6.50033 34.9258 6.93164 35.3001 7.51758 35.5117H0.779297C1.78841 35.1374 2.29297 34.0225 2.29297 32.167V3.7002C2.29297 2.6748 2.15462 1.90169 1.87793 1.38086C1.61751 0.84375 1.16992 0.469401 0.535156 0.257812H7.66406C6.44336 0.713542 5.83301 1.861 5.83301 3.7002V32.167Z'
                        fill='white'
                    />
                    <path
                        d='M26.3154 36.5615L13.6689 3.65137C13.0342 2.00749 12.0495 0.876302 10.7148 0.257812H17.9414C17.3229 0.550781 17.0137 0.96582 17.0137 1.50293C17.0137 2.1377 17.2008 2.9515 17.5752 3.94434L27.6582 30.7998L37.8877 3.67578C38.262 2.68294 38.4492 1.93424 38.4492 1.42969C38.4492 0.92513 38.1481 0.534505 37.5459 0.257812H43.5029C42.152 0.876302 41.151 2.0319 40.5 3.72461L27.8047 36.5615H26.3154Z'
                        fill='white'
                    />
                </svg>
            </span>

            <span className={cn(classes.Number, { [classes.Current]: activeSlide === 5 })} data-id={5}>
                <svg width={34} height={37} viewBox='0 0 34 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M16.2607 36.5615L3.61426 3.65137C2.97949 2.00749 1.99479 0.876302 0.660156 0.257812H7.88672C7.26823 0.550781 6.95898 0.96582 6.95898 1.50293C6.95898 2.1377 7.14616 2.9515 7.52051 3.94434L17.6035 30.7998L27.833 3.67578C28.2074 2.68294 28.3945 1.93424 28.3945 1.42969C28.3945 0.92513 28.0934 0.534505 27.4912 0.257812H33.4482C32.0973 0.876302 31.0964 2.0319 30.4453 3.72461L17.75 36.5615H16.2607Z'
                        fill='white'
                    />
                </svg>
            </span>

            <span className={cn(classes.Number, { [classes.Current]: activeSlide === 6 })} data-id={6}>
                <svg width={44} height={37} viewBox='0 0 44 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M16.2061 36.5615L3.55957 3.65137C2.9248 2.00749 1.9401 0.876302 0.605469 0.257812H7.83203C7.21354 0.550781 6.9043 0.96582 6.9043 1.50293C6.9043 2.1377 7.09147 2.9515 7.46582 3.94434L17.5488 30.7998L27.7783 3.67578C28.1527 2.68294 28.3398 1.93424 28.3398 1.42969C28.3398 0.92513 28.0387 0.534505 27.4365 0.257812H33.3936C32.0426 0.876302 31.0417 2.0319 30.3906 3.72461L17.6953 36.5615H16.2061Z'
                        fill='white'
                    />
                    <path
                        d='M41.9619 32.167C41.9619 33.111 42.0921 33.8516 42.3525 34.3887C42.6292 34.9258 43.0605 35.3001 43.6465 35.5117H36.9082C37.9173 35.1374 38.4219 34.0225 38.4219 32.167V3.7002C38.4219 2.6748 38.2835 1.90169 38.0068 1.38086C37.7464 0.84375 37.2988 0.469401 36.6641 0.257812H43.793C42.5723 0.713542 41.9619 1.861 41.9619 3.7002V32.167Z'
                        fill='white'
                    />
                </svg>
            </span>

            <span className={cn(classes.Number, { [classes.Current]: activeSlide === 7 })} data-id={7}>
                <svg width={57} height={37} viewBox='0 0 57 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M16.1514 36.5615L3.50488 3.65137C2.87012 2.00749 1.88542 0.876302 0.550781 0.257812H7.77734C7.15885 0.550781 6.84961 0.96582 6.84961 1.50293C6.84961 2.1377 7.03678 2.9515 7.41113 3.94434L17.4941 30.7998L27.7236 3.67578C28.098 2.68294 28.2852 1.93424 28.2852 1.42969C28.2852 0.92513 27.984 0.534505 27.3818 0.257812H33.3389C31.988 0.876302 30.987 2.0319 30.3359 3.72461L17.6406 36.5615H16.1514Z'
                        fill='white'
                    />
                    <path
                        d='M41.9072 32.167C41.9072 33.111 42.0374 33.8516 42.2979 34.3887C42.5745 34.9258 43.0059 35.3001 43.5918 35.5117H36.8535C37.8626 35.1374 38.3672 34.0225 38.3672 32.167V3.7002C38.3672 2.6748 38.2288 1.90169 37.9521 1.38086C37.6917 0.84375 37.2441 0.469401 36.6094 0.257812H43.7383C42.5176 0.713542 41.9072 1.861 41.9072 3.7002V32.167Z'
                        fill='white'
                    />
                    <path
                        d='M55.0166 32.167C55.0166 33.111 55.1468 33.8516 55.4072 34.3887C55.6839 34.9258 56.1152 35.3001 56.7012 35.5117H49.9629C50.972 35.1374 51.4766 34.0225 51.4766 32.167V3.7002C51.4766 2.6748 51.3382 1.90169 51.0615 1.38086C50.8011 0.84375 50.3535 0.469401 49.7188 0.257812H56.8477C55.627 0.713542 55.0166 1.861 55.0166 3.7002V32.167Z'
                        fill='white'
                    />
                </svg>
            </span>
        </p>
    )
}

export default Numbers
