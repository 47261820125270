import React, { useContext, useEffect, useRef, useState } from 'react'
import * as classes from "./FirstScreenV2.module.scss"
import { FormContext } from '../../../../context'
import { useWindowSize } from '../../../../hooks/useWindowSize'

import videoDesktopHy from  "../../../../video/main_desktop_v3_hy.mp4"
import videoMobileHy from  "../../../../video/main_mobile_v3_hy.mp4"
import videoDesktopEn from  "../../../../video/main_desktop_v3_en.mp4"
import videoMobileEn from  "../../../../video/main_mobile_v3_en.mp4"

export default function FirstScreenV2({lang}) {
    const [videoView, setVideoView] = useState(null)
    const { animationStart } = useContext(FormContext)
    const video = useRef(null)
    const windowSize = useWindowSize()

    useEffect(() => {
        if (window.innerWidth && !videoView) {
            if (lang === 'en') {
                if (window.innerWidth > 767) {
                    setVideoView(videoDesktopEn)
                } else {
                    setVideoView(videoMobileEn)
                }
            } else {
                if (window.innerWidth > 767) {
                    setVideoView(videoDesktopHy)
                } else {
                    setVideoView(videoMobileHy)
                }
            }
        }

        if (animationStart && videoView) {
            video.current.play()
        }
    }, [animationStart, videoView])

    return (
        <div className={classes.FirstScreenV2}>
            {typeof window !== 'undefined' && videoView ? (
                <video
                    ref={video}
                    muted
                    preload="auto"
                    data-keepplaying="data-keepplaying"
                    playsInline
                    loop
                >
                    <source src={videoView} type="video/mp4"/>
                </video>
            ) : null}
        </div>
    )
}