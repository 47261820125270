import React, { useMemo } from 'react'
import * as classes from './FourthScreen.module.scss'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { deleteHtmlTag } from './../../../../utils/deleteHtmlTag'
import { useDataFourthScreenByHy } from './useDataFourthScreenByHy'
import { useDataFourthScreenByEn } from './useDataFourthScreenByEn'

function FourthScreen({ lang }) {
    const dataByHy = useDataFourthScreenByHy()
    const dataByEn = useDataFourthScreenByEn()

    const data = lang === 'hy' ? dataByHy : dataByEn

    const sectionTitle = useMemo(() => deleteHtmlTag(data.sectionTitle.value, 'p'), [])
    const totalNum = useMemo(() => deleteHtmlTag(data.totalNum.value, 'p'), [])
    const totalText = useMemo(() => deleteHtmlTag(data.totalText.value, 'p'), [])

    const items = useMemo(
        () => [
            {
                count: deleteHtmlTag(data.itemTitle_1.value, 'p'),
                title: deleteHtmlTag(data.itemText_1.value, 'p')
            },

            {
                count: deleteHtmlTag(data.itemTitle_2.value, 'p'),
                title: deleteHtmlTag(data.itemText_2.value, 'p')
            },

            {
                count: deleteHtmlTag(data.itemTitle_3.value, 'p'),
                title: deleteHtmlTag(data.itemText_3.value, 'p')
            },

            {
                count: deleteHtmlTag(data.itemTitle_4.value, 'p'),
                title: deleteHtmlTag(data.itemText_4.value, 'p')
            }
        ],
        []
    )

    return (
        <div className={classes.Inner}>
            <div className={`${classes.AllContent}  main-slider-content`}>
                <SectionTitle className={classes.SectionTitle} lang={lang} title={sectionTitle} light />

                <div className={classes.Content}>
                    <div className={classes.RowInfo}>
                        <div className={classes.Total}>
                            <p className={classes.TotalNum}>
                                {totalNum}
                                <span className={classes.TotalPlus}>+</span>
                            </p>

                            <p className={classes.TotalText} dangerouslySetInnerHTML={{ __html: totalText }} />
                        </div>
                    </div>

                    <div className={classes.RowDetails}>
                        <div className={classes.Details}>
                            {items.map((item, index) => (
                                <div className={classes.DetailsItem} key={index}>
                                    <span className={classes.DetailsItemNum} dangerouslySetInnerHTML={{ __html: item.count }} />

                                    <p className={classes.DetailsItemText} dangerouslySetInnerHTML={{ __html: item.title }} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FourthScreen
