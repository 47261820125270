import anime from 'animejs'

export function onNextSlide(activeSlide, setActiveSlide, positionDefaultScrollControlRef, isTablet) {
    const scrollControlElem = document.getElementById(`scrollControl`)
    const scrollControlDotWrapElems = document.querySelectorAll(`#scrollControlV2-DotWrap`)

    const activeDotWrap = document.getElementById(`active-DotWrap`)
    const scrollControlNumbers = document.getElementById(`scrollControl-numbers`)

    const childActiveDotWrap = activeDotWrap.querySelector('div')

    scrollControlDotWrapElems.forEach((elem) =>
        anime({
            targets: elem,
            duration: 750,
            easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
            paddingBottom: [56, `${isTablet ? 298 : 477}`]
        })
    )

    anime({
        targets: scrollControlElem,
        duration: 750,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
        width: [118, `${isTablet ? 600 : 958}`],
        height: [118, `${isTablet ? 600 : 958}`]
    })

    // появление номера слайда
    anime({
        targets: scrollControlNumbers,
        duration: 150,
        delay: 600,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
        opacity: [0, 1]
    })

    // поворот круга с точками
    anime({
        targets: scrollControlElem,
        duration: 750,
        delay: 750,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
        translateX: ['-50%', '-50%'],
        translateY: ['-50%', '-50%'],
        rotate: [`${positionDefaultScrollControlRef.current}`, `${positionDefaultScrollControlRef.current + 30}`]
    })

    anime({
        targets: activeDotWrap,
        duration: 750,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
        paddingBottom: [56, `${isTablet ? 234 : 413}`]
    })

    anime({
        targets: childActiveDotWrap,
        duration: 750,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
        width: [4, 132],
        height: [4, 132]
    })

    positionDefaultScrollControlRef.current += 30

    const nextSlide = activeSlide === 7 ? 0 : activeSlide + 1

    const currentElem = document.getElementById(`intro-slide-${activeSlide}`)
    const nextElem = document.getElementById(`intro-slide-${nextSlide}`)

    const contentByCurrentElem = currentElem.querySelector('.main-slider-content')
    const contentByNextElem = nextElem.querySelector('.main-slider-content')

    const nameSlideBySixthScreenByCurrent = currentElem.querySelectorAll('.main-slider-inner-name')
    const btnNextBySixthScreenByByCurrent = currentElem.querySelector('.main-slider-btnNext')
    const nameSlideBySixthScreenByNext = nextElem.querySelectorAll('.main-slider-inner-name')
    const btnNextBySixthScreenByNext = nextElem.querySelector('.main-slider-btnNext')

    const prev = scrollControlNumbers.querySelector(`[data-id="${activeSlide}"]`)
    const next = scrollControlNumbers.querySelector(`[data-id="${nextSlide}"]`)

    scrollControlElem.style.transform = `rotate(${positionDefaultScrollControlRef.current})`

    prev.style.top = '50%'
    prev.style.transform = `translate(-50%, -50%)`

    anime({
        targets: currentElem,
        duration: 1500,
        easing: 'cubicBezier(0.8, 0, 0.2, 1)',
        top: [0, '-100%'],
        scale: [1, 0.8]
    })

    anime({
        targets: nextElem,
        duration: 1500,
        easing: 'cubicBezier(0.8, 0, 0.2, 1)',
        top: ['100%', '0%'],
        scale: [0.8, 1]
    })

    // контент внутри слайда current
    anime({
        targets: contentByCurrentElem,
        duration: 750,
        easing: 'cubicBezier(0.42, 0, 1, 1)',
        opacity: [1, 0]
    })

    // контент внутри слайда next
    anime({
        targets: contentByNextElem,
        delay: 750,
        duration: 750,
        easing: 'cubicBezier(0.42, 0, 1, 1)',
        opacity: [0, 1]
    })

    if (nameSlideBySixthScreenByNext) {
        anime({
            targets: nameSlideBySixthScreenByNext,
            delay: 750,
            duration: 750,
            easing: 'cubicBezier(0.42, 0, 1, 1)',
            opacity: [0, 1]
        })
    }

    if (btnNextBySixthScreenByNext) {
        anime({
            targets: btnNextBySixthScreenByNext,
            delay: 750,
            duration: 750,
            easing: 'cubicBezier(0.42, 0, 1, 1)',
            opacity: [0, 1]
        })
    }

    if (nameSlideBySixthScreenByCurrent) {
        anime({
            targets: nameSlideBySixthScreenByCurrent,
            duration: 750,
            easing: 'cubicBezier(0.42, 0, 1, 1)',
            opacity: [1, 0]
        })
    }

    if (btnNextBySixthScreenByByCurrent) {
        anime({
            targets: btnNextBySixthScreenByByCurrent,
            duration: 750,
            easing: 'cubicBezier(0.42, 0, 1, 1)',
            opacity: [1, 0]
        })
    }

    // номера слайдов
    anime({
        targets: prev,
        duration: 750,
        delay: 750,
        easing: 'cubicBezier(0.42, 0, 1, 1)',
        top: [`${50}%`, `${-100}%`],
        opacity: [1, 0]
    })

    anime({
        targets: next,
        duration: 750,
        delay: 750,
        easing: 'cubicBezier(0.42, 0, 1, 1)',
        top: [`${100}%`, `${0}%`],
        translateX: ['-50%', '-50%'],
        opacity: [0, 1]
    })

    // скрытие круга
    setTimeout(() => {
        anime({
            targets: scrollControlNumbers,
            duration: 300,
            delay: 1200,
            easing: 'cubicBezier(0, 0, 0.58, 1)',
            opacity: [1, 0]
        })

        anime({
            targets: scrollControlElem,
            delay: 1000,
            duration: 750,
            easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
            width: [`${isTablet ? 600 : 958}`, 118],
            height: [`${isTablet ? 600 : 958}`, 118]
        })

        scrollControlDotWrapElems.forEach((elem) => {
            anime({
                targets: elem,
                delay: 1000,
                duration: 750,
                easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
                paddingBottom: [`${isTablet ? 298 : 477}`, 56]
            })
        })

        anime({
            targets: activeDotWrap,
            delay: 1000,
            duration: 750,
            easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
            paddingBottom: [`${isTablet ? 234 : 413}`, 56]
        })

        anime({
            targets: childActiveDotWrap,
            delay: 1000,
            duration: 750,
            easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
            width: [132, 4],
            height: [132, 4]
        })

        setActiveSlide(nextSlide)
    }, 1500)
}
