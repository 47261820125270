// extracted by mini-css-extract-plugin
export var Inner = "SeventhScreen-module--Inner--6WcER";
export var AllContent = "SeventhScreen-module--AllContent--zErYb";
export var SectionTitle = "SeventhScreen-module--SectionTitle--xvEra";
export var PageNavigationWrap = "SeventhScreen-module--PageNavigationWrap--y8YZ8";
export var Links = "SeventhScreen-module--Links--D-Nae";
export var Link = "SeventhScreen-module--Link--glqSR";
export var IconRightWrap = "SeventhScreen-module--IconRightWrap--4u9up";
export var Line = "SeventhScreen-module--Line--NbvtG";
export var Decor = "SeventhScreen-module--Decor--aiJ9+";
export var Footer = "SeventhScreen-module--Footer--WuAvP";
export var Copyright = "SeventhScreen-module--Copyright--dN3Tx";
export var Policy = "SeventhScreen-module--Policy--cLHtQ";
export var Author = "SeventhScreen-module--Author--XVDnO";
export var Hidden = "SeventhScreen-module--Hidden--634ln";
export var arrowLeft = "SeventhScreen-module--arrowLeft--XpMtt";
export var arrowRight = "SeventhScreen-module--arrowRight--69YfX";