// extracted by mini-css-extract-plugin
export var Inner = "FifthScreen-module--Inner--beq1X";
export var AllContent = "FifthScreen-module--AllContent--HiIFI";
export var SectionTitle = "FifthScreen-module--SectionTitle--yKhbN";
export var Row = "FifthScreen-module--Row--OxDYK";
export var Total = "FifthScreen-module--Total--IqQb1";
export var TotalNum = "FifthScreen-module--TotalNum--mCA9l";
export var TotalPlus = "FifthScreen-module--TotalPlus--U5JVL";
export var TotalText = "FifthScreen-module--TotalText--ZyBJj";
export var Details = "FifthScreen-module--Details--n5rXC";
export var DetailsItem = "FifthScreen-module--DetailsItem--VebPZ";
export var DetailsItemNum = "FifthScreen-module--DetailsItemNum--6R9-2";
export var DetailsItemText = "FifthScreen-module--DetailsItemText--ku7JH";
export var arrowLeft = "FifthScreen-module--arrowLeft--1l+Ch";
export var arrowRight = "FifthScreen-module--arrowRight--TxIgN";