// extracted by mini-css-extract-plugin
export var Inner = "FourthScreen-module--Inner--vj4JW";
export var AllContent = "FourthScreen-module--AllContent--lx5lT";
export var SectionTitle = "FourthScreen-module--SectionTitle--ig+Zn";
export var Content = "FourthScreen-module--Content--BONi7";
export var RowInfo = "FourthScreen-module--RowInfo--JmyCI";
export var TotalNum = "FourthScreen-module--TotalNum--o3FD4";
export var TotalPlus = "FourthScreen-module--TotalPlus--7z4iM";
export var TotalText = "FourthScreen-module--TotalText--sc2mN";
export var RowDetails = "FourthScreen-module--RowDetails--YsGYz";
export var Details = "FourthScreen-module--Details--gyYhO";
export var DetailsItem = "FourthScreen-module--DetailsItem--fXgVm";
export var DetailsItemNum = "FourthScreen-module--DetailsItemNum--7mDMr";
export var DetailsItemText = "FourthScreen-module--DetailsItemText--uVYlT";
export var arrowLeft = "FourthScreen-module--arrowLeft--rUZlu";
export var arrowRight = "FourthScreen-module--arrowRight--3HK6m";