import { useStaticQuery, graphql } from 'gatsby'

export function useDataFifthScreenByHy() {
    const data = useStaticQuery(graphql`
        {
            sectionTitle: datoCmsRenshinPageMain(title: { eq: "Slide 5: Title" }, locale: { eq: "hy" }) {
                value
            }

            totalNum: datoCmsRenshinPageMain(title: { eq: "Slide 5: Block 1, title" }, locale: { eq: "hy" }) {
                value
            }

            totalText: datoCmsRenshinPageMain(title: { eq: "Slide 5: Block 1, value" }, locale: { eq: "hy" }) {
                value
            }

            itemTitle_1: datoCmsRenshinPageMain(title: { eq: "Slide 5: Block 2, title" }, locale: { eq: "hy" }) {
                value
            }

            itemText_1: datoCmsRenshinPageMain(title: { eq: "Slide 5: Block 2, value" }, locale: { eq: "hy" }) {
                value
            }

            itemTitle_2: datoCmsRenshinPageMain(title: { eq: "Slide 5: Block 3, title" }, locale: { eq: "hy" }) {
                value
            }

            itemText_2: datoCmsRenshinPageMain(title: { eq: "Slide 5: Block 3, value" }, locale: { eq: "hy" }) {
                value
            }
        }
    `)

    return data
}
