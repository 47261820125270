// extracted by mini-css-extract-plugin
export var Inner = "SixthScreen-module--Inner--HVgOE";
export var AllContent = "SixthScreen-module--AllContent--aCjEY";
export var SectionTitle = "SixthScreen-module--SectionTitle--KLa5O";
export var Title = "SixthScreen-module--Title--voIU9";
export var LangEN = "SixthScreen-module--LangEN--jkPCC";
export var Slides = "SixthScreen-module--Slides--F3b7Q";
export var ImageWrap = "SixthScreen-module--ImageWrap--kTjjt";
export var Image = "SixthScreen-module--Image--HSt87";
export var animationImgMainCurrent = "SixthScreen-module--animationImgMainCurrent--KMpUA";
export var animationImgMainNext = "SixthScreen-module--animationImgMainNext--OAGL1";
export var SlideTitle = "SixthScreen-module--SlideTitle--UCr7d";
export var SlideTitle__768 = "SixthScreen-module--SlideTitle__768--8XARg";
export var animationTextMainCurrent = "SixthScreen-module--animationTextMainCurrent--mqa4e";
export var animationTextMainNext = "SixthScreen-module--animationTextMainNext--C0eYh";
export var BtnNext = "SixthScreen-module--BtnNext--fZvww";
export var IconRightWrap = "SixthScreen-module--IconRightWrap--10xRL";
export var Dots = "SixthScreen-module--Dots--tDJMy";
export var TitleMobile = "SixthScreen-module--TitleMobile--GaHDX";
export var Dot = "SixthScreen-module--Dot--ioYKh";
export var arrowLeft = "SixthScreen-module--arrowLeft--uCNMI";
export var arrowRight = "SixthScreen-module--arrowRight--YWgxY";