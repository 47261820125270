import { useStaticQuery, graphql } from 'gatsby'

export function useDataSixthScreenByHy() {
    const data = useStaticQuery(graphql`
        {
            sectionTitle: datoCmsRenshinPageMain(title: { eq: "Slide 6: Title small" }, locale: { eq: "hy" }) {
                value
            }

            title: datoCmsRenshinPageMain(title: { eq: "Slide 6: Title" }, locale: { eq: "hy" }) {
                value
            }

            projects: allDatoCmsRenshinProject(filter: { locale: { eq: "hy" } }) {
                nodes {
                    title
                    urlCode
                    mainBackground {
                        url
                    }
                }
            }
        }
    `)

    return data
}
