// extracted by mini-css-extract-plugin
export var Inner = "ThirdScreen-module--Inner--cmh8Z";
export var LangHY = "ThirdScreen-module--LangHY--us8Ue";
export var Text = "ThirdScreen-module--Text--6Di5Q";
export var AllContent = "ThirdScreen-module--AllContent--90Bwc";
export var SectionTitle = "ThirdScreen-module--SectionTitle--Po808";
export var Circle = "ThirdScreen-module--Circle--NXUyz";
export var Content = "ThirdScreen-module--Content--nvHWc";
export var Title = "ThirdScreen-module--Title--LpBAp";
export var Btn = "ThirdScreen-module--Btn--UQwZY";
export var arrowLeft = "ThirdScreen-module--arrowLeft--VO+Ih";
export var arrowRight = "ThirdScreen-module--arrowRight--i2pCV";