import React, { useMemo } from 'react'
import * as classes from './ThirdScreen.module.scss'
import cn from 'classnames'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { Button } from '../../../ui/Button/Button'
import { deleteHtmlTag } from './../../../../utils/deleteHtmlTag'
import { useDataThirdScreenByEn } from './useDataThirdScreenByEn'
import { useDataThirdScreenByHy } from './useDataThirdScreenByHy'
import { navigate } from 'gatsby'

function ThirdScreen({ lang }) {
    const dataByEn = useDataThirdScreenByEn()
    const dataByHy = useDataThirdScreenByHy()

    const data = lang === 'hy' ? dataByHy : dataByEn

    const sectionTitle = useMemo(() => deleteHtmlTag(data.sectionTitle.value, 'p'), [])
    const title = useMemo(() => deleteHtmlTag(data.title.value, 'p'), [])
    const text = useMemo(() => deleteHtmlTag(data.text.value, 'p'), [])
    const buttonText = useMemo(() => deleteHtmlTag(data.buttonText.value, 'p'), [])

    const buttonUrl = lang === 'hy' ? `/company/about/` : `/en/company/about/`

    return (
        <div className={cn(classes.Inner, { [classes.LangHY]: lang === 'hy' })}>
            <div className={`${classes.AllContent} main-slider-content`}>
                <SectionTitle className={classes.SectionTitle} title={sectionTitle} lang={lang} />

                <div className={classes.Circle} />

                <div className={classes.Content}>
                    <h2 className={classes.Title} dangerouslySetInnerHTML={{ __html: title }} />
                    <p className={classes.Text} dangerouslySetInnerHTML={{ __html: text }} />

                    <Button className={classes.Btn} onClick={() => navigate(buttonUrl)}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ThirdScreen
