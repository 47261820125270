// extracted by mini-css-extract-plugin
export var Circle = "ScrollControlTablet-module--Circle--eeOfc";
export var Dark = "ScrollControlTablet-module--Dark--RJ-d1";
export var Animation = "ScrollControlTablet-module--Animation--wnGap";
export var AnimationLine = "ScrollControlTablet-module--AnimationLine--0-fIL";
export var Text = "ScrollControlTablet-module--Text--bg-yw";
export var Control = "ScrollControlTablet-module--Control--PJfHq";
export var ActiveDot = "ScrollControlTablet-module--ActiveDot--D+WFK";
export var Dot = "ScrollControlTablet-module--Dot--eBVA3";
export var animationLine = "ScrollControlTablet-module--animationLine--PJC6V";
export var arrowLeft = "ScrollControlTablet-module--arrowLeft--Zo5B6";
export var arrowRight = "ScrollControlTablet-module--arrowRight--uSu1c";