import React, { useState, useRef } from 'react'
import * as classes from './Main.module.scss'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import cn from 'classnames'
import Header from '../../ui/Header/Header'
import screen_2 from '../../../images/main/screen_2.jpeg'
import screen_4 from '../../../images/main/screen_4.jpeg'
import screen_5 from '../../../images/main/screen_5.jpeg'
import SecondScreen from './SecondScreen/SecondScreen'
import ThirdScreen from './ThirdScreen/ThirdScreen'
import FourthScreen from './FourthScreen/FourthScreen'
import FifthScreen from './FifthScreen/FifthScreen'
import SixthScreen from './SixthScreen/SixthScreen'
import SeventhScreen from './SeventhScreen/SeventhScreen'
import ScrollControl from './ScrollControl/ScrollControl'
import { useWindowSize } from '../../../hooks/useWindowSize'
import ScrollControlTablet from './ScrollControlTablet/ScrollControlTablet'
import { onNextSlideTablet } from './ScrollControlTablet/onNextSlideTablet'
import { onNextSlide } from './onNextSlide'
import { onPrevSlide } from './onPrevSlide'
import { onPrevSlideTablet } from './ScrollControlTablet/onPrevSlideTablet'
import FirstScreenV2 from './FirstScreenV2/FirstScreenV2'

const dots = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }]

const numbers = [
    { id: 1, text: 'I' },
    { id: 2, text: 'II' },
    { id: 3, text: 'III' },
    { id: 4, text: 'IV' },
    { id: 5, text: 'V' },
    { id: 6, text: 'VI' },
    { id: 7, text: 'VII' }
]

function Main({ lang }) {
    const windowSize = useWindowSize()

    const [activeSlide, setActiveSlide] = useState(1)
    const [isDisabled, setIsDisabled] = useState(false)

    const positionDefaultScrollControlRef = useRef(0)

    const isTablet = windowSize.width >= 1024 && windowSize.width <= 1280
    const isMobail = windowSize.width < 768

    const nextSlide = () => {
        if (isDisabled) {
            return
        }

        if (activeSlide + 1 > 7) {
            return false
        }

        setIsDisabled(true)

        setTimeout(() => {
            setIsDisabled(false)
        }, 3000)

        if (windowSize.width <= 768) {
            onNextSlideTablet(activeSlide, setActiveSlide, isMobail)
            return
        }

        onNextSlide(activeSlide, setActiveSlide, positionDefaultScrollControlRef, isTablet)
    }

    const prevSlide = () => {
        if (isDisabled) {
            return
        }

        if (activeSlide - 1 === 0) {
            return false
        }

        setIsDisabled(true)

        setTimeout(() => {
            setIsDisabled(false)
        }, 3000)

        if (windowSize.width <= 768) {
            onPrevSlideTablet(activeSlide, setActiveSlide, isMobail)
            return
        }

        onPrevSlide(activeSlide, setActiveSlide, positionDefaultScrollControlRef, isTablet)
    }

    return (
        <ReactScrollWheelHandler
            upHandler={prevSlide}
            downHandler={nextSlide}
            wheelConfig={[5, 20, 0.05]}
        >
            <section className={classes.Section}>
                <Header light={activeSlide !== 3} lang={lang} />

                <div className={classes.Slides}>
                    <div className={cn(classes.Slide, { [classes.Active]: activeSlide === 1 })} id='intro-slide-1' data-id='1'>
                        <FirstScreenV2 lang={lang} />
                    </div>

                    <div
                        className={cn(classes.Slide, { [classes.Active]: activeSlide === 2 })}
                        data-id='2'
                        style={{ backgroundImage: `url(${screen_2})` }}
                        id='intro-slide-2'
                    >
                        <SecondScreen lang={lang} />
                    </div>

                    <div className={cn(classes.Slide, { [classes.Active]: activeSlide === 3 })} id='intro-slide-3' data-id='3'>
                        <ThirdScreen lang={lang} />
                    </div>

                    <div
                        className={cn(classes.Slide, { [classes.Active]: activeSlide === 4 })}
                        data-id='4'
                        style={{ backgroundImage: `url(${screen_4})` }}
                        id='intro-slide-4'
                    >
                        <FourthScreen lang={lang} />
                    </div>

                    <div
                        className={cn(classes.Slide, { [classes.Active]: activeSlide === 5 })}
                        data-id='5'
                        style={{ backgroundImage: `url(${screen_5})` }}
                        id='intro-slide-5'
                    >
                        <FifthScreen lang={lang} />
                    </div>

                    <div className={cn(classes.Slide, { [classes.Active]: activeSlide === 6 })} id='intro-slide-6' data-id='6'>
                        <SixthScreen lang={lang} nextSlide={nextSlide} prevSlide={prevSlide} />
                    </div>

                    <div className={cn(classes.Slide, { [classes.Active]: activeSlide === 7 })} id='intro-slide-7' data-id='7'>
                        <SeventhScreen lang={lang} />
                    </div>
                </div>

                {windowSize.width > 768 ? (
                    <ScrollControl activeSlide={activeSlide} dots={dots} numbers={numbers} />
                ) : (
                    <ScrollControlTablet activeSlide={activeSlide} dots={dots} numbers={numbers} />
                )}
            </section>
        </ReactScrollWheelHandler>
    )
}

export default Main
